<template>
  <div class="wap-car">
    <div class="page-title flex-center">{{ $t('common.user-tab3') }}</div>

    <div class="wap-car-list">
      <div
        class="action-btn flex-center-between"
        v-if="shoppingList.length > 0"
      >
        <div></div>
        <div :class="showAction ? 'red' : ''" @click="showAction = !showAction">
          {{ !showAction ? $t("guan-li") : $t("tui-chu-guan-li") }}
        </div>
      </div>
      <div class="shop" v-for="(shop, index) in shoppingList" :key="index">
        <div class="name flex-center-between">
          <div class="flex-center">
            <Checkbox
              v-model="shop.checked"
              @click="changeShop(shop, index)"
              checked-color="#9d48f7"
              icon-size="16px"
            ></Checkbox>
            <span class="ml-10">{{ shop.shopName || $t("dian-pu-ming-cheng") }}</span>
          </div>
        </div>

        <div class="product-list">
          <div
            class="list flex-center"
            v-for="(goods, i) in shop.goodsList"
            :key="i"
          >
            <Checkbox
              v-model="goods.checked"
              @click="changeGoods(goods, i, index)"
              checked-color="#9d48f7"
              icon-size="16px"
            ></Checkbox>
            <div class="img">
              <img :src="goods.BusinessProduct.MainImage" alt="" />
            </div>
            <div class="flex-1 info">
              <div class="goods-name">
                {{
                  goods.BusinessProduct.ProductTranslation &&
                  goods.BusinessProduct.ProductTranslation[0]
                    ? goods.BusinessProduct.ProductTranslation[0].Name
                    : ''
                }}
              </div>
              <div class="options">{{ goods.optionName }}</div>
              <div class="flex-center-between">
                <div class="price">
                  ${{ goods.optionPrice || goods.BusinessProduct.SalePrice }}
                </div>
                <el-input-number
                  class="gray"
                  size="mini"
                  v-model="goods.Number"
                  :min="1"
                ></el-input-number>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="action-line flex-center-between" v-if="shoppingList.length > 0">
      <div class="flex-center">
        <Checkbox
          v-model="allChecked"
          @click="checkAll"
          checked-color="#9d48f7"
          icon-size="16px"
        ></Checkbox>
        {{ $t('quan-xuan') }}
      </div>
      <div>
        <div v-if="!showAction">
          {{ $t('gong-ji-0') }}
          <span class="color-main bold big">${{ totalMoney }}</span>
        </div>
        <div>
          {{ $t('xuan-ze') }}
          <span class="color-main">{{ chooseList.length }}</span
          >{{ $t('ge') }}
        </div>
      </div>
      <div>
        <Button v-if="!showAction" @click="toBuy">{{
          $t('li-ji-gou-mai')
        }}</Button>
        <Button class="red" v-else @click="removeGoods">{{
          $t('shan-chu')
        }}</Button>
      </div>
    </div>

    <div class="wap-empty-box" v-if="shoppingList.length == 0">
      <img :src="emptyUrl" alt="" />
      <div class="desc">{{ $t('gou-wu-che-kong-kong') }}</div>
      <Button @click="toPath('wapGoods')">{{ $t('li-ji-gou-wu') }}</Button>
    </div>
  </div>
</template>
<script>
import { carList, removeCar } from '@/api/user'
import { Button, Checkbox, Popup, Icon } from 'vant'
export default {
  name: 'about',
  components: {
    Button,
    Popup,
    Icon,
    Checkbox
  },
  data() {
    return {
      emptyUrl: require('@/assets/imgs/empty.png'),
      shoppingList: [],
      chooseList: [],
      allGoodsList: [],
      allChecked: false,
      showAction: false
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    totalMoney() {
      return this.chooseList
        .reduce((total, product) => {
          return (
            total +
            (product.optionPrice || product.BusinessProduct.SalePrice) *
              product.Number
          )
        }, 0)
        .toFixed(2)
    },
    isLogin() {
      return this.$store.state.isLogin
    }
  },
  watch: {
    isLogin(val) {
      if (val) {
        this.init()
      }
    }
  },
  mounted() {
    if (this.isLogin) {
      this.init()
    }
  },
  methods: {
    init() {
      if (!this.isLogin) {
        return
      }
      carList().then((res) => {
        let list = res.data.Items
        let shoppingList = []
        this.allGoodsList = []
        this.chooseList = []
        list.forEach((v) => {
          let shop = v.BusinessProduct.ShopID
          let shopName = v.BusinessProduct.Shop.Name
          let optionPrice = v.BusinessProductSpecificationsRelation.ID
            ? v.BusinessProductSpecificationsRelation.Price
            : ''
          if (optionPrice > 0) {
            v.SalePrice = optionPrice
          }
          let optionName = ''
          if (v.ProductSpecifications && v.ProductSpecifications.length > 0) {
            optionName = v.ProductSpecifications.map((v) => {
              return v.Name + ':' + v.Value
            }).join(';')
          }
          let index = shoppingList.findIndex((v) => {
            return v.ShopID == shop
          })
          if (index > -1) {
            shoppingList[index].goodsList.push({
              ...v,
              optionName: optionName,
              optionPrice: optionPrice,
              checked: true
            })
          } else {
            let param = {
              ShopID: shop,
              shopName: shopName,
              checked: true,
              goodsList: [
                {
                  ...v,
                  optionName: optionName,
                  optionPrice: optionPrice,
                  checked: true
                }
              ]
            }
            shoppingList.push(param)
          }
        })
        this.shoppingList = shoppingList
        this.shoppingList.forEach((v) => {
          this.chooseList = this.chooseList.concat(v.goodsList)
        })
        this.allChecked = true
        this.allGoodsList = [].concat(this.chooseList)
      })
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    changeShop(data, i) {
      let checked = data.checked
      this.shoppingList[i].goodsList.forEach((v) => {
        v.checked = checked
      })
      this.$forceUpdate()
      this.resetChoose()
    },
    changeGoods(data, i, index) {
      let list = this.shoppingList[index].goodsList.filter((v) => {
        return v.checked
      })
      if (list.length === this.shoppingList[index].goodsList.length) {
        this.shoppingList[index].checked = true
      } else {
        this.shoppingList[index].checked = false
      }
      this.resetChoose()
    },
    resetChoose() {
      this.chooseList = []
      this.shoppingList.forEach((v) => {
        v.goodsList.forEach((val) => {
          if (val.checked) {
            this.chooseList.push(val)
          }
        })
      })
      if (this.chooseList.length === this.allGoodsList.length) {
        this.allChecked = true
      } else {
        this.allChecked = false
      }
    },
    removeGoods() {
      let index = 0
      this.chooseList.forEach((data) => {
        removeCar({
          ID: data.ID
        }).then((res) => {
          if (res.code == 0) {
            index += 1
            if (index === this.chooseList.length) {
              this.$toast(this.$t('shan-chu-cheng-gong'))
              this.init()
            }
          } else {
            this.$toast(res.msg)
          }
        })
      })
    },
    submit() {
      let orderData = this.shoppingList.map((v) => {
        return {
          Shop: v.ShopID,
          goodsList: v.goodsList.map((val) => {
            return {
              ...val.BusinessProduct,
              BusinessProductSpecificationsRelation:
                val.BusinessProductSpecificationsRelation,
              BusinessProductSpecificationsRelationID:
                val.BusinessProductSpecificationsRelationID,
              count: val.Number,
              optionName: val.optionName,
              optionPrice: val.optionPrice
            }
          })
        }
      })

      sessionStorage.setItem('orderData', JSON.stringify(orderData))
      // this.$router.push({
      //   name: 'order'
      // })
    },
    checkAll() {
      let checked = this.allChecked
      this.shoppingList.forEach((v) => {
        v.checked = checked
        v.goodsList.forEach((val) => {
          val.checked = checked
        })
      })
      this.$forceUpdate()
      this.resetChoose()
    },
    toBuy() {
      if (!this.isLogin) {
        this.$router.push({
          name: 'userLogin'
        })
        return
      }
      let orderData = this.shoppingList.map((v) => {
        return {
          Shop: v.ShopID,
          goodsList: v.goodsList.map((val) => {
            return {
              ...val.BusinessProduct,
              BusinessProductSpecificationsRelation:
                val.BusinessProductSpecificationsRelation,
              BusinessProductSpecificationsRelationID:
                val.BusinessProductSpecificationsRelationID,
              count: val.Number,
              optionName: val.optionName,
              optionPrice: val.optionPrice
            }
          })
        }
      })

      sessionStorage.setItem('orderData', JSON.stringify(orderData))
      this.$router.push({
        name: 'wapToOrder'
      })
    }
  }
}
</script>
<style lang="less">
</style>
